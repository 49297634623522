import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/scss/main.scss'
import Header from '../components/header'
import Footer from '../components/footer'
import ContactUsBlock from '../components/contact-us-block'
import Locations from '../components/locations'
import ScrollBack from '../components/scroll-back'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        wp {
          siteOptions {
            settings {
              mainNav {
                text
                link
              }
              locationBlock {
                title
                items {
                  title
                  address
                  mobile
                }
                email {
                  label
                  text
                }
                linkedin {
                  label
                  text
                  link
                }
              }
              contactUsBlock {
                title
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <Header {...data} />
          <main>{ children }
            <ScrollBack />
            <ContactUsBlock {...data} />
            <Locations {...data} />
            <Footer {...data} />
          </main>
        </>
      )
    }
    }
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
