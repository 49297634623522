import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import ContactForm from './contact-form'

class ContactUsBlock extends Component {

  render() {

    let { title } = this.props.wp.siteOptions.settings.contactUsBlock

    return (
      <section className='contact-us' data-color='#000000' data-text='#FFFFFF'>
        <div className='contact-us__inner'>
          <div className='contact-us__content'>
            <Fade bottom distance='30px'>
            <div className='contact-us__left-panel'>
              <h2 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            </Fade>
            <Fade bottom distance='30px'>
              <div className='contact-us__right-panel'>
                <ContactForm />
              </div>
            </Fade>
          </div>
        </div>
      </section>
    )
  }
}

export default ContactUsBlock