import React, { Component } from 'react'
import Link from '../utils/link'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <p><Link to='/terms-and-conditions/'>Terms, conditions and privacy policy</Link></p>
        </div>
      </footer>
    )
  }
}

export default Footer
