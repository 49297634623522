import React, { Component } from 'react'
import Link from '../utils/link'
import Locations from '../components/locations'
import Typist from 'react-typist'

class Header extends Component {

  state = {
    offCanvas: false,
    initial: true,
    hover: true,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
    setTimeout(() => {
      this.setState({ initial: false })
    }, 1000)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  toggleScrolled = () => {
    this.setState({ scrolled: !this.state.scrolled })
  }

  onScroll = (e) => {
    let scrolled = window.scrollY > 1
    if (scrolled !== this.state.scrolled) {
      requestAnimationFrame(this.toggleScrolled)
    }
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
    document && document.querySelector('.locations').classList.remove('menu-active')
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
    document && document.querySelector('.locations').classList.toggle('menu-active')
  }

  render() {

    let { offCanvas, hover, initial, scrolled } = this.state

    let { mainNav } = this.props.wp.siteOptions.settings

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) headerClass += ' active'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <div className='header__content'>
              <Link to='/' title='Thompson Cook' className='header__logo' {...props} onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
                TC | { (initial || hover ) ? <Typist>Recruitment</Typist> : 'Recruitment' }
              </Link>

              <div className="header__menu" >

                <span onClick={this._toggleOffCanvas}>{ offCanvas ? 'close' : 'menu' }</span>

                <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                  <span className='lines'></span>
                </button>

              </div>

            </div>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'active' }`}>
          <div className='off-canvas__inner'>

            { mainNav && mainNav.length > 0 &&
              <nav className='off-canvas__nav'>
                <ul>
                  { mainNav.map((el, i) => (
                    <li key={i}>
                      { el.link && <Link to={el.link} key={i} {...props}>{el.text}</Link> }
                    </li>
                  )) }
                </ul>
              </nav>
            }

            <Locations {...this.props} />

          </div>
        </div>
      </>
    )
  }
}

export default Header
