import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import Link from '../utils/link';
import LogoDark from '../assets/images/logo-dark.svg'
import LogoLight from '../assets/images/logo-light.svg'

class Locations extends Component {

  render() {

    let { title, items, email, linkedin } = this.props.wp.siteOptions.settings.locationBlock

    return (
      <section className='locations'>

        <div className='locations__inner'>

          <div className='locations__heading'> 
            <Fade bottom distance='30px'>
              <h2 dangerouslySetInnerHTML={{ __html: title }} />
            </Fade>
          </div>

          <div className='locations__content'>

            { items && 

              <div className='locations__items'>

                  {items.map((el, i) => (

                    <Fade bottom distance='30px'>

                        <div className='locations__item' key={i}>

                          <h4 dangerouslySetInnerHTML={{ __html: el.title}} />

                          <div className='locations__item-content' dangerouslySetInnerHTML={{ __html: el.address}} />

                          <p className='locations__item-mobile'><Link to={el.mobile}>{el.mobile}</Link></p>

                        </div>

                      </Fade>

                  ))}

              </div>

            }

            <div className='locations__contact-details'>

              <Fade bottom distance='30px'>
                <div className='locations__email'>
                  <img className='logo logo--dark' src={LogoDark} alt='Thompson Cook' />
                  <img className='logo logo--light' src={LogoLight} alt='Thompson Cook' />
                  <span><Link to={`mailto:${email.text}`}>{ email.text }</Link></span>
                  <span>{ linkedin.link && <Link to={ linkedin.link }>{ linkedin.text }</Link> }</span>
                </div>
              </Fade>

            </div>

          </div>

        </div>

      </section>
    )
  }
}

export default Locations