import React, { Component } from 'react'

import UpArrow from '../assets/images/up-arrow.svg'


class ScrollBack extends Component {

  render() {

    return (

      <section className='scroll-back'>

        <div className='scroll-back__inner'>

          <div className='scroll-back__content'>

            <div className='scroll-back__text' onClick={() => window && window.scroll({ top: 0, left: 0, behavior: 'smooth'})}>
              <p>Back to top</p>
            </div>

            <img src={UpArrow} alt="Back to top" />

          </div>
            
        </div>

      </section>
    )
  }
}

export default ScrollBack